
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/ManropeSemiBold.ttf"); font-family : "Manrope"; font-weight : 600;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/ManropeExtraBold.ttf"); font-family : "Manrope"; font-weight : 800;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/QuicksandSemiBold.ttf"); font-family : "Quicksand"; font-weight : 600;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/QuicksandRegular.ttf"); font-family : "Quicksand"; font-weight : 500;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/ManropeMedium.ttf"); font-family : "Manrope"; font-weight : 500;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/QuicksandMedium.ttf"); font-family : "Quicksand"; font-weight : 600;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/ManropeRegular.ttf"); font-family : "Manrope"; font-weight : 400;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/InterBold.ttf"); font-family : "Inter"; font-weight : 700;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/ManropeBold.ttf"); font-family : "Manrope"; font-weight : 700;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/QuicksandBold.ttf"); font-family : "Quicksand"; font-weight : 700;  }
 @font-face { font-display: swap; font-style : normal; src: url("assets/fonts/InterRegular.ttf"); font-family : "Inter"; font-weight : 400;  }