:root { 
--black_900_7f:#0000007f; 
--black_900_b2:#000000b2; 
--light_green_A700:#3cd500; 
--lime_A200:#f2ff26; 
--gray_800_04:#3c3b3b; 
--deep_orange_100_01:#ffb6b6; 
--gray_800_03:#464444; 
--gray_800_02:#6f4439; 
--deep_orange_100_02:#ffbda7; 
--red_400:#dd6a57; 
--gray_800_01:#4d4d4d; 
--deep_orange_100_03:#fcb0b0; 
--green_A100:#c7ffe0; 
--amber_A100:#ffe985; 
--yellow_400:#f2fb59; 
--white_A700_56:#ffffff56; 
--blue_gray_900_02:#333333; 
--blue_gray_900_01:#263238; 
--blue_gray_900:#343a36; 
--deep_orange_100:#ffb8b8; 
--red_A200_01:#f96364; 
--gray_400:#b4ccb9; 
--blue_gray_100:#cccccc; 
--lime_A100_99:#f6fe7599; 
--gray_800:#394240; 
--amber_100:#f9ebb2; 
--orange_200:#ffca6c; 
--amber_100_01:#fee9b4; 
--red_200_01:#ecafad; 
--green_200:#92e3a9; 
--deep_orange_A200:#fa742b; 
--green_800_cc:#07933ccc; 
--blue_gray_100_01:#d5d5d5; 
--deep_orange_400_01:#f76d57; 
--light_green_100:#dbd2c1; 
--red_300:#ae7461; 
--amber_A200:#f7d940; 
--green_A200:#7bde9e; 
--gray_50:#fafafa; 
--deep_orange_A100_01:#ffb573; 
--yellow_500:#fff720; 
--gray_500_01:#abaaaa; 
--pink_800:#9a4a4d; 
--gray_500_02:#999999; 
--deep_orange_400:#ed893e; 
--red_300_02:#b16668; 
--gray_500:#a6a6a6; 
--gray_900:#1b1b1b; 
--red_300_01:#d3766a; 
--amber_300_01:#f1cc4d; 
--blue_gray_800_01:#3f3d56; 
--gray_100:#f2f2f2; 
--green_A200_01:#59f293; 
--orange_50_01:#fdefe2; 
--green_A200_02:#5af293; 
--blue_400:#47a7dd; 
--deep_orange_A100:#f0997a; 
--red_200:#f28f8f; 
--black_900_3f:#0000003f; 
--green_500:#3acd64; 
--white_A700_99:#ffffff99; 
--yellow_600:#fccf31; 
--amber_A100_01:#ffdb6c; 
--green_A700:#28c76f; 
--gray_200_01:#f0f0f0; 
--green_A100_01:#a7eabf; 
--blue_gray_700:#455a64; 
--yellow_200:#faffad; 
--gray_600:#a0616a; 
--red_A200:#fc583d; 
--yellow_50:#fffaee; 
--amber_300:#fdd367; 
--red_A100_cc:#ff8686cc; 
--gray_200:#ebebeb; 
--gray_400_01:#c7c7c7; 
--gray_400_02:#bfbfbf; 
--gray_100_01:#f5f5f5; 
--white_A700:#ffffff; 
--blue_gray_700_01:#403472; 
--lime_A100:#f5fd75; 
--red_400_02:#ef6161; 
--green_800:#07933c; 
--red_400_01:#f55555; 
--deep_purple_100:#d0cde1; 
--yellow_400_cc:#fafc50cc; 
--green_500_99:#3bcd6499; 
--amber_A400:#ffc100; 
--green_A400:#00f35c; 
--green_400:#51d27f; 
--black_900:#000000; 
--light_green_700:#6f9b45; 
--blue_gray_800:#37474f; 
--deep_orange_200:#ffa8a7; 
--green_A400_01:#28d173; 
--pink_200:#ff9abb; 
--gray_900_02:#232323; 
--gray_900_03:#202020; 
--gray_700:#686767; 
--gray_900_04:#161616; 
--green_800_01:#2d6a4f; 
--gray_600_02:#757575; 
--gray_600_01:#717171; 
--gray_900_05:#111918; 
--blue_gray_900_04:#2f2e41; 
--red_A100:#fc8a8a; 
--gray_900_01:#1a1a1a; 
--blue_gray_900_03:#2e2e2e; 
--amber_200:#fedd8e; 
--gray_900_06:#282828; 
--gray_300:#dbdbdb; 
--gray_300_02:#e0e0e0; 
--orange_300:#e9c558; 
--gray_900_07:#231f20; 
--gray_300_01:#e6e6e6; 
--green_500_01:#3bcd64; 
--orange_50:#fff3d9; 
--black_900_cc:#000000cc; 
--green_200_7a:#92e3a97a; 
}